import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Button"
import { FormControl } from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
import StatusTabs from "./StatusTabs";
import PromoTable from "./PromoTable";

const SearchablePromos = props => {
  const {
    promos,
    doFetchGetPromo,
    doFetchUpdatePromo,
    adminUser,
  } = props

  const [search, setSearch] = useState("")

  useEffect(() => {
    doFetchGetPromo({ email: search })
  }, []);

  const handleConfirm = e => {
    e.preventDefault()
    doFetchGetPromo(search)
  }

  return (
    <div>
      <Grid container>
        <Grid item md={12}>
          <FormControl style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <Grid container>
              <Grid item xs={3} style={{ marginRight: "30px" }}>
                <div style={{ marginBottom: '-5px' }}><label>Email</label></div>
                <TextField
                  value={search ? search : ""}
                  type="text"
                  onChange={e => setSearch(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={3} style={{ marginRight: "30px" }}>
                <div>
                  <Button
                    autoFocus
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "10px" }}
                  >
                    Search
                  </Button>
                </div>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <StatusTabs
        viewNames={["All Promos", "Pending Review"]}
      >
        <PromoTable
          promos={promos}
          doFetchUpdatePromo={doFetchUpdatePromo}
          adminUser={adminUser}
        />
        <PromoTable
          promos={promos}
          doFetchUpdatePromo={doFetchUpdatePromo}
          adminUser={adminUser}
          statusFilter="pending_review"
        />
      </StatusTabs>
    </div>
  )
}

export default SearchablePromos;
