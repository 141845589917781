import * as constants from "../../constants";
import fetch from "../util/api-ajax";

export const createErrorMessage = (errorMessage) => ({
  type: constants.PROMO_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.PROMO_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.PROMO_CLOSE_SNACKBAR });
};

function setIsFetching(isFetching) {
  return {
    type: constants.PROMO_IS_FETCHING,
    isFetching,
  };
}

function getPromoSuccess(payload) {
  return {
    type: constants.PROMO_GET_PROMO_SUCCESS,
    payload,
  };
}

export function getPromo({ email, status }) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.PROMO_LIST_PROMO, { email, status })
      .then((res) => {
        dispatch(getPromoSuccess(res))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}

export function getPromoByMemberID({ memberID, status }) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.MEMBER_GET_URL, { memberID })
      .then((res) => {
        return fetch(constants.PROMO_LIST_PROMO, { user_id: res.userID, status })
          .then((res) => {
            dispatch(getPromoSuccess(res))
          })
          .finally(() => dispatch(setIsFetching(false)))
          .catch((error) => {
            dispatch(setErrorMessage(error.message));
          });
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}

function updatePromoSuccess(payload) {
  return {
    type: constants.PROMO_UPDATE_PROMO_SUCCESS,
    payload,
  };
}

export function updatePromoByID(id, status, statusReason) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.PROMO_UPDATE_PROMO, statusReason !== null ? {
      id,
      status,
      status_reason: statusReason,
      status_reason_valid: true
    } : {
      id,
      status,
      status_reason: '',
      status_reason_valid: true
    })
      .then(() => {
        dispatch(updatePromoSuccess({ id, status, statusReason }))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}