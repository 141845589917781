import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import * as status from "./pageStatus";

function getApprovalsSuccess(payload) {
  return {
    type: constants.LMI_APPROVAL_LIST_APPROVALS_SUCCESS,
    payload,
  };
}

function fetchLMITypesSuccess(payload) {
  return {
    type: constants.LMI_APPROVAL_GET_LMI_TYPES_SUCCESS,
    payload,
  };
}

function fetchLMIDocumentsSuccess(payload) {
  return {
    type: constants.LMI_APPROVAL_GET_LMI_DOCUMENTS_SUCCESS,
    payload,
  };
}

function fetchLMIDetailsSuccess(payload) {
  return {
    type: constants.LMI_APPROVAL_GET_LMI_DETAILS_SUCCESS,
    payload,
  };
}

export function getApprovals() {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const res = await fetch(constants.LMI_APPROVAL_LIST_APPROVALS);
      const data = await fetch(constants.GET_LMI_TYPES);
      dispatch(getApprovalsSuccess(res));
      dispatch(fetchLMITypesSuccess(data));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

export function getDetails(memberID) {
  return (dispatch) => {
    dispatch(status.startProcessing());
    return fetch(constants.MEMBER_LIST_DOCUMENTS_URL, { memberID })
      .then((res) => {
        dispatch(fetchLMIDocumentsSuccess(res));
        return fetch(constants.CS_METER_URL, { memberId: memberID })
          .then((res) => {
            dispatch(fetchLMIDetailsSuccess(res));
          })
          .catch(error => {
            dispatch(status.setErrorMessage(error.message));
          })
          .finally(() => {
            dispatch(status.finishProcessing());
          });
      })
  };
}

export function updateMeter(payload) {
  return (dispatch) => {
    dispatch(status.startProcessing());
    return fetch(constants.CS_METER_UPDATE, payload)
      .then((res) => {
        dispatch(status.finishProcessing());
      })
      .catch(error => {
        dispatch(status.setErrorMessage(error.message));
      })
  };
}

export function openMemberDocument(memberID, fileID) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.MEMBER_GET_DOCUMENT_DOWNLOAD_URL, { memberID, fileID });
      window.open(response.download_url, '_blank');
    } catch {
      dispatch(status.setErrorMessage('Error getting download URL.'));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}