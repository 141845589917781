import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import user from "./user";
import { memberSearchResult, selectedMember, memberDocuments, memberNoteTags } from "./members";
import { guest } from "./guest";
import { meterResult } from "./meters";
import usage from "./usage";
import {
  billingSummaryState,
  billingAdjustmentMsg,
  billingTransactions,
  billingMonthlyCharges,
} from "./billing";
import {
  paymentCardList,
  paymentChargeList,
  paymentRefundMsg,
  payments,
} from "./payment";
import { reports } from "./reports";
import { lmiApproval } from "./lmiApproval";
import { jobNotifications } from "./jobNotifications";
import { notifications } from "./notifications";
import { userManagement } from "./userManagement";
import savingsData from "./savings";
import { acceptedPairs } from "./acceptedPairs";
import meterLookupDetails from "./meterLookup";
import dnp from "./dnp";
import dnpRnpHistory from "./dnpRnpHistory";
import accountFlags from "./accountFlags";
import meterTransactions from "./meterTransactions";
import tax from "./tax";
import iterable from "./iterable";
import statements from "./statements";
import transferOfService from "./transferOfService";
import enrollments from "./enrollments";
import renewal from "./renewal";
import referrals, { memberReferrals } from "./referrals";
import pageStatus from "./pageStatus";
import { lookup } from "./lookup";
import csa from "./csa";
import { promo } from "./promo";

const rootReducer = combineReducers({
  user,
  memberSearchResult,
  selectedMember,
  guest,
  meterResult,
  usage,
  billingSummaryState,
  billingAdjustmentMsg,
  billingTransactions,
  billingMonthlyCharges,
  paymentCardList,
  paymentChargeList,
  paymentRefundMsg,
  payments,
  reports,
  lmiApproval,
  jobNotifications,
  notifications,
  userManagement,
  routing,
  savingsData,
  acceptedPairs,
  meterLookupDetails,
  dnp,
  dnpRnpHistory,
  accountFlags,
  meterTransactions,
  tax,
  iterable,
  statements,
  transferOfService,
  enrollments,
  renewal,
  referrals,
  memberReferrals,
  pageStatus,
  memberDocuments,
  csa,
  lookup,
  promo,
  memberNoteTags,
});

export default rootReducer;
