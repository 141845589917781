import React, { useEffect } from "react";
import PromoTable from "./PromoTable";

const MemberPromos = props => {
  const {
    promos,
    doFetchGetPromoByMemberID,
    doFetchUpdatePromo,
    params,
    adminUser,
  } = props


  useEffect(() => {
    doFetchGetPromoByMemberID({ memberID: params.memberId })
  }, []);

  return <PromoTable
    promos={promos}
    doFetchUpdatePromo={doFetchUpdatePromo}
    adminUser={adminUser}
  />
}

export default MemberPromos;
