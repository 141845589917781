import * as constants from "../../constants";
import selectDnpContainer from "../selectors/dnp";
import fetch from "../util/api-ajax";

const createErrorMessage = (message) => ({
  type: constants.DNP_SET_ERROR_MESSAGE,
  message,
});

const createSuccessMessage = (message) => ({
  type: constants.DNP_SET_SUCCESS_MESSAGE,
  message,
});

const getSelectedMeters = (acc, cur) => {
  if (cur.selected) {
    acc.push({
      dnpID: cur.id,
      meter_recordID: cur.ID,
      accountID: cur.account_id,
      memberID: cur.memberID,
    });
  }
  return acc;
};

const toggleIsLoading = () => ({
  type: constants.DNP_TOGGLE_LOADING,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

const sendDnpRequests = (dispatch, m) => {
  const { dnpID, meter_recordID, memberID } = m;
  dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
  return fetch(constants.DNP_DISCONNECT_URL, { meterID: meter_recordID })
    .then(() => {
      dispatch({ type: constants.DNP_TOGGLE_SUCCESS, meter_recordID });
    })
    .then(() => {
      fetch(constants.BILLING_UPDATE_DISCONNECT_NOTICE_URL, {
        id: dnpID,
        status: "disconnected",
        memberID,
      });
    })
    .catch((err) => {
      dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
      dispatch({
        type: constants.DNP_SET_METER_ERROR,
        meter_recordID,
        message: err.message,
      });
    });
};

export const clearAllErrors = () => (dispatch) => {
  dispatch({
    type: constants.DNP_CLEAR_ALL_ERRORS,
  });
};

export const doSendDnpRequests = () => (dispatch, getState) => {
  const { accountsByMeters } = selectDnpContainer()(getState());
  const selectedMeters = accountsByMeters.reduce(getSelectedMeters, []);
  clearAllErrors()(dispatch);
  // want to limit request to max of 10
  selectedMeters.slice(0, 10).map((m) => sendDnpRequests(dispatch, m));
};

const sendSnoozeRequests = (dispatch, meter_recordID, accountID) => {
  dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
  const snoozeFlagId = "SNOOZE";
  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const addHours = (date, hours) => {
    date.setHours(date.getHours() + hours)
    return date
  }
  return fetch(
    `${constants.ACCOUNT_FLAGS_MAIN_URL}/${accountID}/accountflags/create`,
    { account_flag_type_id: snoozeFlagId, expiration_date: formatDate(addHours(new Date(), 18)) }
  )
    .then(() => {
      dispatch({ type: constants.DNP_TOGGLE_SUCCESS, meter_recordID });
    })
    .catch((err) => {
      dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
      dispatch({
        type: constants.DNP_SET_METER_ERROR,
        meter_recordID,
        message: err.message,
      });
    });
};

export const doSendSnoozeRequests = () => (dispatch, getState) => {
  const { accountsByMeters } = selectDnpContainer()(getState());
  const selectedMeters = accountsByMeters.reduce(getSelectedMeters, []);

  clearAllErrors()(dispatch);
  // want to limit request to max of 10
  selectedMeters.slice(0, 10).map((m) => {
    // get the account associated with the meter
    const account = accountsByMeters.find(
      (a) => a.meters.findIndex((am) => am.ID == m.meter_recordID) !== -1
    );

    if (account) {
      sendSnoozeRequests(dispatch, m.meter_recordID, account.account_id);
    }
  });
};

export const doFetchDnpCandidates = () => (dispatch) => {
  const fields = {
    limit: 1000,
  };
  dispatch(toggleIsLoading());
  dispatch({
    type: constants.DNP_LOAD_CANDIDATES,
    accounts: [],
  });
  return fetch(constants.DNP_FETCH_CANDIDATES_URL, fields)
    .then((candidates) => {
      const { page, limit, total } = candidates;
      const accounts = candidates.list;
      dispatch({
        type: constants.DNP_LOAD_CANDIDATES,
        page,
        limit,
        total,
        accounts,
      });
      dispatch(toggleIsLoading());
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(toggleIsLoading());
    });
};

export const updateCandidateState = (index, updatedInfo) => (dispatch) => {
  dispatch({
    type: constants.DNP_UPDATE_CANDIDATE,
    updatedInfo,
    index,
  });
};

export const toggleCandidateSelect = (accountID, meterId) => (dispatch) => {
  dispatch({
    type: constants.DNP_TOGGLE_CANDIDATE_SELECT,
    accountID,
    meterId,
  });
};

export const toggleCandidatesSelectAll = () => (dispatch) => {
  dispatch({
    type: constants.DNP_TOGGLE_CANDIDATES_SELECT_ALL,
  });
};
