import * as constants from "../../constants";

const initialState = {
  tdsps: [],
};

export function lookup(state = initialState, action) {
  switch (action.type) {
    case constants.RECEIVE_TDSPS:
      return { ...state, tdsps: action.tdsps };
    default:
      return state;
  }
}
