import React from "react";
import { connect } from "react-redux";
import Promo from "../components/Promo";
import selectPromoContainer from "../selectors/promo";
import {
  getPromo,
  getPromoByMemberID,
  closeSnackbar,
  updatePromoByID
} from "../actions/promo";

const PromoContainer = (props) => <Promo {...props} />;

const mapStateToProps = (state) => ({
  ...selectPromoContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetPromo: props => {
    dispatch(getPromo(props));
  },
  doFetchGetPromoByMemberID: props => {
    dispatch(getPromoByMemberID(props));
  },
  closeSnackbar: () => {
    dispatch(closeSnackbar());
  },
  doFetchUpdatePromo: (id, status, statusReason) => {
    dispatch(updatePromoByID(id, status, statusReason));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoContainer);
