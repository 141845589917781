import React, { useState, useEffect, Fragment } from "react";
import Button from "../Button"
import ReactTable from "react-table";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const PromoTable = ({ promos, doFetchUpdatePromo, adminUser, statusFilter }) => {

  const [promoList, setPromoList] = useState([])
  const [showApprove, setShowApprove] = useState(false)
  const [showReject, setShowReject] = useState(false)
  const [selectedPromo, setSelectedPromo] = useState(null)
  const [statusReason, setStatusReason] = useState(null)
  const [rejectionList, setRejectionList] = useState([])

  useEffect(() => {
    const filteredPromos = statusFilter ? promos.filter(promo => promo.status === statusFilter) : promos
    setPromoList(filteredPromos)
  }, [promos])


  const columns = () => {
    let cols = [
      {
        Header: "Promo ID",
        accessor: "id",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      },
      {
        Header: "Submitted Date",
        accessor: "created",
        Cell: ({ value }) => {
          const date = new Date(value)
          const month = date.getUTCMonth() + 1
          const day = date.getUTCDate()
          const year = date.getUTCFullYear()
          return (<div style={{ textAlign: 'center' }}>{`${month}/${day}/${year}`}</div>)
        }
      },
      {
        Header: "Promo Type",
        accessor: "promo_type",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      },
      {
        Header: "Meter Identifier",
        accessor: "meter_identifier",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      },
      {
        Header: "Meter Identifier 2",
        accessor: "meter_identifier2",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      }, {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      }, {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      }, {
        Header: "Status Reason",
        accessor: "status_reason",
        Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
      },
    ]
    if (adminUser.permissions.isPromoManager) {
      cols.push({
        Header: "",
        accessor: "ID",
        width: 200,
        Cell: (row) => (
          <Fragment>
            <Button
              onClick={() => {
                setStatusReason(null)
                setSelectedPromo(row.original)
                setRejectionList([])
                setShowApprove(true)
              }}
              variant="contained"
              color="secondary"
              disabled={(row.original.status === 'approved' || row.original.status === 'fulfilled')}
            >
              Approve
            </Button>
            <Button
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setStatusReason(null)
                setSelectedPromo(row.original)
                setRejectionList(row.original?.promo_type_meta_data?.validation_options?.rejected || [])
                setShowReject(true)
              }}
              variant="contained"
              color="secondary"
              disabled={(row.original.status === 'rejected' || row.original.status === 'fulfilled')}
            >
              Reject
            </Button>
          </Fragment>
        ),
      })
    }
    return cols
  }

  const handleApprove = () => {
    if (selectedPromo) {
      doFetchUpdatePromo(selectedPromo.id, 'approved', null)
      setShowApprove(false)
    }
  }

  const handleReject = () => {
    if (selectedPromo && statusReason !== null) {
      doFetchUpdatePromo(selectedPromo.id, 'rejected', statusReason)
      setShowReject(false)
    }
  }

  return (
    <div style={{ marginTop: 10 }}>
      {
        promoList && promoList.length > 0 ?
          <Fragment>
            <ReactTable
              data={promoList}
              columns={columns()}
              className="-striped -highlight"
              defaultPageSize={10}
              defaultSortDesc
              filterable
            />
          </Fragment> :
          <div>
            There are no promotions to show
          </div>
      }
      <Dialog
        onClose={() => setShowApprove(!showApprove)}
        open={showApprove}
      >
        <DialogTitle onClose={() => setShowApprove(!showApprove)}>
          <span>Please Confirm</span>
        </DialogTitle>
        <DialogContent dividers>
          <div>Are you sure you want to approve this promo?</div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowApprove(!showApprove)} color='secondary'>
            Close
          </Button>
          <Button
            autoFocus
            onClick={handleApprove}
            color='primary'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setShowReject(!showReject)}
        open={showReject}
      >
        <DialogTitle onClose={() => setShowReject(!showReject)}>
          <span>Please Confirm</span>
        </DialogTitle>
        <DialogContent dividers>
          <div>Are you sure you want to reject this promo?</div>
          <div style={{ width: "100%", marginTop: "10px" }}>Status Reason</div>
          <Select
            value={statusReason}
            onChange={e => setStatusReason(e.target.value)}
            input={<OutlinedInput label='Chip' />}
            style={{ width: "100%", marginTop: "10px" }}
          >
            {rejectionList.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowReject(!showReject)} color='secondary'>
            Close
          </Button>
          <Button
            autoFocus
            onClick={handleReject}
            color='primary'
            disabled={statusReason === null}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PromoTable;
