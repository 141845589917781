import "babel-polyfill";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestTdspsInfo() {
  return {
    type: constants.REQUEST_TDSPS,
    isFetching: true,
  };
}

function receiveTdspsInfo(json) {
  return {
    type: constants.RECEIVE_TDSPS,
    isFetching: false,
    tdsps: json,
  };
}

export function requestTdsps() {
  return (dispatch, getState) => {
    if (getState().lookup.tdsps.length === 0) {
      dispatch(requestTdspsInfo());
      return fetch(constants.TDSP_URL).then((res) => {
        dispatch(receiveTdspsInfo(res.tdsps));
      })
    }
  };
}