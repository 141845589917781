import { createSelector } from "reselect";

const notifications = () => (state) => state.notifications;

const selectNotificationsContainer = () =>
  createSelector(notifications(), (notificationsData) => {
    return notificationsData;
  });

export default selectNotificationsContainer;
