import { createSelector } from "reselect";

const promo = () => (state) => {
  return {
    ...state.promo,
    selectedMember: state.selectedMember,
    adminUser: state.user,
  }
};

const selectPromoContainer = () =>
  createSelector(promo(), (PromoData) => {
    return PromoData;
  });

export default selectPromoContainer;
