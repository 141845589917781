import { createSelector } from "reselect";

const lmiApproval = () => (state) => state.lmiApproval;

const selectLmiApprovalContainer = () =>
  createSelector(lmiApproval(), (data) => {
    return data;
  });

export default selectLmiApprovalContainer;
