import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import * as status from "./pageStatus";

function setIsFetching(isFetching) {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching,
  };
}

function getReportsSuccess(payload) {
  return {
    type: constants.REPORTS_GET_REPORTS_SUCCESS,
    payload,
  };
}

function runReportsSuccess(payload) {
  return {
    type: constants.REPORTS_RUN_REPORT_SUCCESS,
    payload,
  };
}

export function getReports() {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.REPORTS_LIST_REPORTS)
      .then((res) => {
        dispatch(getReportsSuccess(res))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}
 
export function runReport(id) {
  return (dispatch) => {
    dispatch(status.startProcessing());
    dispatch(setIsFetching(true));    
    return fetch(constants.REPORTS_RUN_REPORT, { report_id: id })
      .then((res) => {
        dispatch(runReportsSuccess(res))
        dispatch(status.finishProcessing())
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(status.setErrorMessage(error.message));
        dispatch(status.finishProcessing())
      });
  };
}