import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ReactTable from "react-table";
import StatusOverlay from "../StatusOverlay"
import { Link } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Tooltip } from "@material-ui/core";
import Button from "../Button"
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const PreviewMode = ({ openDialog, setOpenDialog, documents, approval, details, lmiTypes, updateApproval, doOpenDocument }) => {
  const [lmiType, setLmiType] = useState(null);
  const [lmiStatus, setLmiStatus] = useState(null);

  useEffect(() => {
    if (details !== null) {
      setLmiStatus(details.lmi)
      setLmiType(details.lmi_type)
    }
  }, [details]);

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>
        Review Account
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={6} style={{ padding: '10px' }}>
              <label>Email</label>
              <div style={{ color: 'black' }}>{approval && (approval.email || '-')}</div>
            </Grid>
            <Grid item sm={6} style={{ padding: '10px' }}>
              <label>Bill to Name</label>
              <div style={{ color: 'black' }}>{approval && (approval.bill_to_name || '-')}</div>
            </Grid>
            <Grid item sm={6} style={{ padding: '10px' }}>
              <label>Meter Identifier</label>
              <div style={{ color: 'black' }}>{approval && (approval.meter_identifier || '-')}</div>
            </Grid>
            <Grid item sm={6} style={{ padding: '10px' }}>
              <label>Billing Account Number</label>
              <div style={{ color: 'black' }}>{approval && (approval.billing_account_number || '-')}</div>
            </Grid>
            <Grid item sm={6} style={{ padding: '10px' }}>
              <label>Status</label>
              <Select
                value={lmiStatus}
                onChange={e => setLmiStatus(e.target.value)}
                input={<OutlinedInput label='Chip' />}
                style={{ width: "100%", marginTop: "10px" }}
              >
                {['Review', 'Yes', 'No'].map((name) => (
                  <MenuItem key={name} value={name.toLowerCase()}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} style={{ padding: '10px' }}>
              <label>Type</label>
              <Select
                value={lmiType}
                onChange={e => setLmiType(e.target.value)}
                input={<OutlinedInput label='Chip' />}
                style={{ width: "100%", marginTop: "10px" }}
              >
                {lmiTypes.filter(x => (
                  (details !== null && x.eligible_states.includes(details.meta_data.address.state)) ||
                  (details === null)
                )).map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={12} style={{ padding: '10px' }}>
              <label>LMI Documents</label>
              <List style={{ color: 'black' }}>
                {
                  documents && documents.map((item, index) => (
                    <ListItem style={{ paddingLeft: '0px' }} key={index} button onClick={e => {
                      e.preventDefault()
                      doOpenDocument(approval.member_id, item.fileID);
                    }}>
                      <Link>{item.file_name}</Link>
                    </ListItem>
                  ))
                }
              </List>
              {
                documents.length === 0 && <div style={{ color: 'red' }}>There are no documents available</div>
              }
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            const payload = { id: details.id, lmi: lmiStatus }
            if (lmiType !== 0) {
              payload['lmi_type'] = lmiType
            }
            await updateApproval(payload)
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 5,
            marginBottom: 10,
          }}
        >
          Update
        </Button>
        <Button
          onClick={() => setOpenDialog(false)}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog >
  )
}

const LmiApproval = props => {
  const [selectedApproval, setSelectedApproval] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { approvals, lmiTypes, doFetchGetApprovals, doFetchGetDetails, approval, documents, doFetchUpdateMeter, doOpenDocument } = props;
  const columns = [
    {
      Header: 'Email Address',
      accessor: 'email',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: 'Phone Number',
      accessor: 'phone',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: 'Bill to Name',
      accessor: 'bill_to_name',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      id: 'member_id',
      Header: 'Member ID',
      accessor: (row) => {
        if (row.member_id) {
          return <Link to={`/members/${row.member_id}/details`}>{row.member_id}</Link>
        }
        return row.member_id
      },
    },
    {
      Header: 'Billing Account Number',
      accessor: 'billing_account_number',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: 'Meter Identifier',
      accessor: 'meter_identifier',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: 'Meter Identifier2',
      accessor: 'meter_identifier2',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      id: 'service_address',
      Header: 'Service Address',
      accessor: (row) => (<div style={{ textAlign: 'center' }}>{row.meta_data.address.line1}</div>)
    },
    {
      Header: 'Utility Name',
      accessor: 'utility_name',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: 'Enrollment Complete Date',
      accessor: 'enrollment_completion_date',
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: '',
      id: 'commands',
      width: 120,
      accessor: (row) => (
        <Tooltip placement='top' title='Review Account'>
          <Button
            onClick={async () => {
              await doFetchGetDetails(row.member_id)
              setSelectedApproval(row)
              setOpenDialog(true)
            }}
            variant='contained'
            color='primary'
            disabled={row.deleted}
            style={{ marginRight: '5px' }}
          >
            Review
          </Button>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    doFetchGetApprovals({});
  }, []);

  const handleUpdate = async payload => {
    await doFetchUpdateMeter(payload);
    await doFetchGetApprovals({});
    setOpenDialog(false);
    setSelectedApproval(null);
  }

  return (
    <div>
      <StatusOverlay />
      <Grid container>
        <Grid item sm={6}>
          <h4>LMI Approval</h4>
        </Grid>
      </Grid>
      <br />
      <ReactTable
        data={approvals}
        columns={columns}
        className="-striped -highlight"
        defaultPageSize={10}
        defaultSortDesc
        filterable
      />
      <PreviewMode
        approval={selectedApproval}
        details={approval}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        lmiTypes={lmiTypes}
        documents={documents}
        updateApproval={handleUpdate}
        doOpenDocument={doOpenDocument}
      />
    </div>
  );
}

LmiApproval.propTypes = {
  approvals: PropTypes.array.isRequired,
  lmiTypes: PropTypes.array.isRequired,
};

export default LmiApproval