import React from "react";
import { connect } from "react-redux";
import LmiApproval from "../components/LmiApproval";
import selectLmiApprovalsContainer from "../selectors/lmiApproval";
import {
  getApprovals,
  getDetails,
  updateMeter,
  openMemberDocument,
} from "../actions/lmiApproval";

const LmiApprovalContainer = (props) => <LmiApproval {...props} />;

const mapStateToProps = (state) => ({
  ...selectLmiApprovalsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetApprovals: () => {
    dispatch(getApprovals());
  },
  doFetchGetDetails: memberID => {
    dispatch(getDetails(memberID));
  },
  doFetchUpdateMeter: payload => {
    dispatch(updateMeter(payload));
  },
  doOpenDocument: (memberID, documentID) => {
    dispatch(openMemberDocument(memberID, documentID));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(LmiApprovalContainer);
