import React, { useState } from "react";
import Tab from "@material-ui/core/Tab";
import ViewSelector from "../common/ViewSelector";
import SwipeableViews from "react-swipeable-views";

const StatusTabs = ({
  children,
  viewNames,
}) => {
  const [activeView, setActiveView] = useState(0);

  return (
    <div >
      <ViewSelector
        viewNames={viewNames}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        {viewNames.map((option, index) => {
          return (
            <Tab
              key={index}
              className={activeView === index ? `view activeView` : `view`}
              label={option}
            ></Tab>
          );
        })}
      </ViewSelector>
      <SwipeableViews
        axis="x"
        index={activeView}
      >
        {children}
      </SwipeableViews>
    </div>
  );
};

export default StatusTabs;
