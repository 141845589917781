import "babel-polyfill";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { setSelectedCSA } from "./csa";

const MEMBER_PAGE_LIMIT = 10;

function requestMembers() {
  return {
    type: constants.REQUEST_MEMBERS,
    isFetching: true,
  };
}

function searchByEsi(payload) {
  return {
    type: constants.ESI_SEARCH,
    payload,
  };
}

export function receiveMembers(json) {
  return {
    type: constants.RECEIVE_MEMBERS,
    isFetching: false,
    listOfMembers: json.Members,
    page: json.page,
  };
}

function receiveGuestMembers(json) {
  return {
    type: constants.RECEIVE_GUEST_MEMBERS,
    isFetching: false,
    listOfMembers: json.Members,
  };
}

function receiveSelectedUserID(userID) {
  return {
    type: constants.RECEIVE_SELECTED_USER_ID,
    isFetching: false,
    userID,
  };
}

export function setCurrentMember(member) {
  return {
    type: constants.SELECTED_MEMBER,
    member,
  };
}

function sortOn(property) {
  return (a, b) => {
    if (a[property] < b[property]) {
      return -1;
    } else if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  };
}

function createMemberPromiseArray(members, getState) {
  return members.Members.map((member) => {
    const modfiedMember = member;
    if (member.service_provider === "proton_meter") {
      return fetch(constants.METER_SEARCH_URL, {
        memberID: member.memberID,
      }).then((meter) => {
        const valid = meter && meter.meters[0] && meter.meters[0].service_address;
        if (valid) {
          modfiedMember.meterAddress = meter.meters[0].service_address.lineOne;
          modfiedMember.meterState = meter.meters[0].service_address.state;
        }
        return modfiedMember;
      }).catch(() => {
        return modfiedMember;
      });
    } else if (member.service_provider === "cs_meter") {
      return fetch(constants.CS_METER_URL, {
        memberId: member.memberID,
      }).then((meter) => {
        const valid = meter && meter.meter && meter.meter.meta_data && meter.meter.meta_data.address;
        if (valid) {
          modfiedMember.meterAddress = meter.meter.meta_data.address.line1;
          modfiedMember.meterState = meter.meter.meta_data.address.state;
          modfiedMember.meterDetail = { ...meter.meter, meterIdentifier: meter.meter.meter_identifier };
        }
        return modfiedMember;
      }).catch(() => {
        return modfiedMember;
      });
    } else if (member.service_provider === "eci_meter") {
      return fetch(constants.ECI_METER_URL, {
        memberId: member.memberID,
      }).then((meter) => {
        const valid = meter && meter.meter && meter.meter.serviceAddress;
        if (valid) {
          modfiedMember.meterAddress = meter.meter.serviceAddress.line1;
          modfiedMember.meterState = meter.meter.serviceAddress.state;
        }
        return modfiedMember;
      }).catch(() => {
        return modfiedMember;
      });
    } else {
      return modfiedMember;
    }
  });
}

function createMemberBillingInfo(memberIDs) {
  return fetch(constants.BILLING_ACCOUNTS, {
    memberIDs: memberIDs,
    return_last_successful_payment: "true",
  })
    .then((res) => {
      return res.accounts;
    })
    .catch((err) => { });
}

function createMemberBillingInfoPromiseArray(members) {
  return members.map((member) => {
    const modfiedMember = member;
    return fetch(constants.BILLING_ACCOUNT, {
      memberID: member.memberID,
    })
      .then((res) => {
        modfiedMember.account_balance = res.account_balance;
        modfiedMember.last_successful_payment = res.last_successful_payment;
        return modfiedMember;
      })
      .catch((err) => {
        modfiedMember.account_balance = "N/A";
        modfiedMember.last_successful_payment = "N/A";
        return modfiedMember;
      });
  });
}

export function clearSearchResults() {
  return {
    type: constants.CLEAR_SEARCH_RESULTS,
  };
}

function incrementPageCount(page) {
  return {
    type: constants.INCREMENT_SEARCH_RESULTS_PAGE_COUNT,
    page,
  };
}

export const setIsLoading = (isLoading) => {
  return {
    type: constants.UPDATE_LOADING_SEARCH_STATE,
    isLoading,
  };
};

export async function addMeterInfoToMember(members, dispatch, getState) {
  let filterValues = members.map((member) => {
    return member.memberID;
  });

  let limit = members.length;

  return fetch(constants.GET_METER_LIST, {
    filters: [
      {
        name: "member_id",
        values: filterValues,
      },
    ],
    limit,
  })
    .then((meterDetailList) => meterDetailList.meters)
    .then((meterDetailList) => {
      let meters = {};
      // Map meter array to object based on memberID
      meterDetailList.map((meterDetail) => {
        meters[meterDetail.memberID] = meterDetail;
      });
      // Append meter detail to each member from meters object
      members.map((member) => {
        if (meters[member.memberID]) {
          member.meterDetail = meters[member.memberID];
        }
      });
      return members;
    });
}

async function addMeterAddressToMember(members, dispatch, getState) {
  // Get list of member IDs for billing/accounts/ endpoint
  const memberIDs = members.Members.map((member) => member.memberID);

  await Promise.all(createMemberPromiseArray(members, getState)).then(
    (modifiedMembers) => {
      if (modifiedMembers && modifiedMembers.length < MEMBER_PAGE_LIMIT) {
        dispatch(searchPaginationComplete());
      }
      if (modifiedMembers && modifiedMembers.length === 0) {
        dispatch(receiveMembers({ Members: [{ no_results: true }] }));
        dispatch(setIsLoading(false));
        return;
      }
      addMeterInfoToMember(modifiedMembers, dispatch, getState).then(
        async (membersWithMeterInfo) => {
          const membersBillingInfoResp = await createMemberBillingInfo(
            memberIDs
          );
          const modifiedMembersWithBillingInfo = membersWithMeterInfo.map(
            (member) => {
              const billingInfo = membersBillingInfoResp.find(
                (billing) => billing.memberID === member.memberID
              );
              member.account_balance = billingInfo?.account_balance;
              member.last_successful_payment =
                billingInfo?.last_successful_payment?.amount;
              return member;
            }
          );
          dispatch(receiveMembers({ Members: modifiedMembersWithBillingInfo }));
          dispatch(setIsLoading(false));
        }
      );
    }
  )
}

export function search(fields) {
  return (dispatch, getState) => {
    dispatch(requestMembers());
    dispatch(clearSearchResults());

    if (fields.meterIdentifier !== "") {
      dispatch(searchByEsi(fields));
      return;
    }

    if (fields.user_id !== "") {
      return fetch(constants.MEMBER_GET_ALL, {
        userID: fields.user_id,
      }).then((memberJson) => {
        const setMember = async () => {
          await addMeterAddressToMember(memberJson, dispatch, getState);
        };
        setMember();
      });
    }

    if (fields.phone !== "" || fields.email !== "") {
      return fetch(constants.USER_SEARCH_URL, fields).then((userJson) => {
        const obj = { Members: [] };
        const user = userJson.users && userJson.users[0];

        if (typeof user === "undefined") {
          dispatch(receiveMembers({ Members: [{ no_results: true }] }));
          dispatch(setIsLoading(false));
          return;
        }

        if (user.gg_status === "active" && user.userID) {
          dispatch(receiveSelectedUserID(user.userID));
          return fetch(constants.GUEST_GET_ALL, {
            userID: user.userID,
          }).then((guest) => {
            dispatch(receiveGuestMembers(guest));
            dispatch(setIsLoading(false));
          });
        } else if (user.userID) {
          return fetch(constants.MEMBER_GET_ALL, {
            userID: user.userID,
          }).then((memberJson) => {
            const setMember = async () => {
              await addMeterAddressToMember(memberJson, dispatch, getState);
            };
            setMember();
          });
        }

        obj.Members.push({ no_results: true });
        dispatch(receiveMembers(obj));
        dispatch(setIsLoading(false));
      });
    }

    return fetch(constants.MEMBER_SEARCH_URL, {
      member: fields,
      accountNumber: fields.accountNumber,
      page: 0,
      limit: MEMBER_PAGE_LIMIT,
    })
      .then((json) => {
        const newJson = json;
        if (!json.Members) {
          newJson.Members = [{ no_results: true }];
        } else {
          if (newJson.Members.length > MEMBER_PAGE_LIMIT - 1) {
            dispatch(searchPaginationStarted());
          }
          newJson.Members.sort(sortOn("last_name"));
        }
        dispatch(incrementPageCount(newJson.page));
        const setMember = async () => {
          await addMeterAddressToMember(newJson, dispatch, getState);
        };
        setMember();
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
      });
  };
}

function searchPaginationStarted() {
  return {
    type: constants.SEARCH_PAGINATION_STARTED,
  };
}

function searchPaginationComplete() {
  return {
    type: constants.SEARCH_PAGINATION_COMPLETE,
  };
}

function memberNotesSuccess(payload) {
  return {
    type: constants.MEMBER_NOTES_GET_SUCCESS,
    payload,
  };
}

function memberNotesFailure(payload) {
  return {
    type: constants.MEMBER_NOTES_GET_FAILURE,
    payload,
  };
}

function fetchMemberNotes(memberID, userID) {
  const opts = { memberID, userID };
  return (dispatch) =>
    fetch(constants.MEMBER_NOTES_GET_URL, opts)
      .then((json) => {
        dispatch(memberNotesSuccess(json.MemberNotes));
      })
      .catch((err) => {
        dispatch(memberNotesFailure(err));
      });
}

function memberDepositSuccess(payload) {
  return {
    type: constants.METER_GET_DEPOSITS_SUCCESS,
    payload,
  };
}

function memberDepositFailure(payload) {
  return {
    type: constants.METER_GET_DEPOSITS_FAILTURE,
    payload,
  };
}

export function fetchMemberDeposit(memberID) {
  const opts = { memberID };
  return (dispatch, getState) => {
    fetch(constants.METER_GET_DEPOSITS, opts)
      .then((res) => {
        dispatch(memberDepositSuccess(res.deposits));
      })
      .catch((err) => {
        dispatch(memberDepositFailure(err));
      });
  }
}

function memberAccountBalanceSuccess(payload) {
  return {
    type: constants.BILLING_GET_ACCOUNT_BALANCE_SUCCESS,
    payload,
  };
}

function memberAccountBalanceFailure(payload) {
  return {
    type: constants.BILLING_GET_ACCOUNT_BALANCE_FAILTURE,
    payload,
  };
}

function accountBalanceTransactionsSuccess(payload) {
  return {
    type: constants.BILLING_GET_ACCOUNT_TRANSACTION_SUCCESS,
    payload,
  };
}

function accountBalanceTransactionsFailure(payload) {
  return {
    type: constants.BILLING_GET_ACCOUNT_TRANSACTION_FAILTURE,
    payload,
  };
}

export function fetchMemberAccountBalance(memberId) {
  const opts = { memberId };
  return (dispatch) =>
    fetch(constants.BILLING_GET_ACCOUNT_BALANCE, opts)
      .then((res) => {
        dispatch(memberAccountBalanceSuccess(res));
      })
      .catch((err) => {
        dispatch(memberAccountBalanceFailure(err));
      });
}

export function fetchAccountBalanceTransactions(opts) {
  return (dispatch) =>
    fetch(constants.BILLING_GET_ACCOUNT_TRANSACTIONS, opts)
      .then((res) => {
        dispatch(accountBalanceTransactionsSuccess(res));
      })
      .catch((err) => {
        dispatch(accountBalanceTransactionsFailure(err));
      });
}

function memberProductSuccess(payload) {
  return {
    type: constants.MEMBER_GET_PRODUCT_SUCCESS,
    payload,
  };
}

function memberProductFailure(payload) {
  return {
    type: constants.MEMBER_GET_PRODUCT_FAILTURE,
    payload,
  };
}

function fetchMemberProduct(memberID) {
  const opts = { memberID };
  return (dispatch) =>
    fetch(constants.MEMBER_GET_PRODUCT, opts)
      .then((json) => {
        dispatch(memberProductSuccess(json.list));
      })
      .catch((err) => {
        dispatch(memberProductFailure(err));
      });
}

export const refreshMemberProduct = (memberID) => {
  return (dispatch) => {
    dispatch(fetchMemberProduct(memberID));
  };
}

function membersAllSuccess(payload) {
  return {
    type: constants.MEMBERS_GET_ALL_SUCCESS,
    payload,
  };
}

function membersAllFailure(payload) {
  return {
    type: constants.MEMBERS_GET_ALL_FAILURE,
    payload,
  };
}

function fetchMembersAll(userID, selectedMemberID) {
  return (dispatch) =>
    fetch(constants.MEMBER_GET_ALL, { userID })
      .then((json) => {
        dispatch(membersAllSuccess({ membersList: json, selectedMemberID }));
        return json;
      })
      .then((json) => {
        json.Members.forEach((m) => {
          dispatch(requestMemberMeterInfo(m.memberID));
        });
      })
      .catch((err) => {
        dispatch(membersAllFailure(err));
      });
}

export function resultsNextPage({ fields, page }) {
  const opts = { member: fields, page, limit: MEMBER_PAGE_LIMIT };
  return (dispatch, getState) =>
    fetch(constants.MEMBER_SEARCH_URL, opts).then((json) => {
      const setMember = async () => {
        await addMeterAddressToMember(json, dispatch, getState);
      };
      setMember();
      dispatch(incrementPageCount(json.page));
      dispatch(setIsLoading(false));
    });
}

export function fetchFullMemberInfoNeeded(member) {
  return (dispatch) => {
    dispatch(requestUserInfo(member.get("userID")));
    dispatch(requestMemberAccountInfo(member.get("memberID")));
    dispatch(requestMeterInfo(member.get("memberID")))
    dispatch(requestStripeAccountID(member.get("userID")));
    dispatch(fetchMemberNotes(member.get("memberID"), member.get("userID")));
    dispatch(fetchMembersAll(member.get("userID"), member.get("memberID")));
    dispatch(fetchMemberProduct(member.get("memberID")));
    dispatch(fetchMemberDeposit(member.get("memberID")));
    dispatch(setSelectedCSA({ memberID: member.get("memberID"), userID: member.get("userID"), allowFailure: true }));
  };
}

export function fetchUserIDWithFullMemberInfoNeeded(memberId) {
  return (dispatch) => {
    dispatch(requestUserId(memberId));
    dispatch(requestMemberAccountInfo(memberId));
    dispatch(requestMeterInfo(memberId))
    // dispatch(requestStripeAccountID(memberId));
    dispatch(fetchMemberProduct(memberId));
    dispatch(fetchMemberDeposit(memberId));
    dispatch(fetchMemberAccountBalance(memberId));
    dispatch(setSelectedCSA({ memberID: memberId, allowFailure: true }));
  };
}

function requestUserId(memberID) {
  return (dispatch) => {
    dispatch(requestMemberInfo());
    return fetch(constants.MEMBER_GET_URL, { memberID }).then((json) => {
      dispatch(receiveMemberUserInfo(json));
      dispatch(requestUserInfo(json.userID));
      dispatch(fetchMemberNotes(memberID, json.userID));
      dispatch(fetchMembersAll(json.userID, memberID));
    });
  };
}

function requestUserInfo(userID) {
  return (dispatch) => {
    dispatch(requestMemberInfo());
    return fetch(constants.GET_USER_URL, { userID }).then((json) => {
      const newJson = json;
      newJson.phoneRaw = newJson.phone; // value that holds the +1 prefix
      newJson.phone = json.phone.replace(/[+]/, "");
      newJson.phone = `(${json.phone.slice(1, 4)}) ${json.phone.slice(
        4,
        7
      )}-${json.phone.slice(7, 11)}`;
      // this is user's active prop, which conflicts on account.active property
      // so we save it to new prop 'userIsActive'
      newJson.userIsActive = json.active;
      //and delete it before passing to the reducer
      delete newJson.active;
      // user.billing_address should not be used anymore
      delete newJson.billing_address;
      dispatch(receiveMemberUserInfo(newJson));
    });
  };
}

function requestMemberAccountInfo(memberID) {
  return (dispatch) => {
    dispatch(requestMemberInfo());
    return fetch(constants.BILLING_ACCOUNT, { memberID }).then((json) => {
      // protect from empty response
      if (json.memberID) {
        dispatch(receiveMemberUserInfo(json));
      }
    });
  };
}

function receiveStripeAccountID(stripeAccountId) {
  return {
    type: constants.RECEIVE_STRIPE_ACCOUNT_ID,
    stripeAccountId,
  };
}

export function requestStripeAccountID(userId) {
  return (dispatch) =>
    fetch(constants.PAYMENT_LIST_CARDS, { userId }).then((data) => {
      dispatch(receiveStripeAccountID(data.result.customerID));
    });
}

export function requestMeterInfo(memberID) {
  return (dispatch, getState) => {
    dispatch(requestMemberInfo());
    return fetch(constants.MEMBER_GET_URL, { memberID }).then((member) => {
      let url = constants.METER_SEARCH_URL
      let query = { include_inactive: false }
      if (member.service_provider === "eci_meter") {
        url = constants.ECI_METER_URL
        query['memberId'] = memberID
      }
      else if (member.service_provider === "cs_meter") {
        url = constants.CS_METER_URL
        query['member_id'] = memberID
      } else {
        url = constants.PROTON_METER_URL
        query['memberID'] = memberID
      }

      return fetch(url, query).then((json) => {
        if (json.meters) {
          // Continue as before
          let newJson = json;
          newJson.service_provider = member.service_provider;
          fetch(constants.METER_LOOKUP, {
            esiID: json.meters[0].meterIdentifier,
          }).then((meterInfo) => {
            newJson.meters[0] = Object.assign(meterInfo, json.meters[0]);
            newJson.meters.map((m) => {
              const newMeter = m;
              const dunsName = constants.TDSP_LOOKUP_DICT[m.duns_number];
              newMeter.utilityDunsNumber = m.duns_number
              if (dunsName) {
                newMeter.duns_number = dunsName;
              }
              return newMeter;
            });
            dispatch(receiveMemberMeterInfo(newJson));
          });
        }
        else if (json.meter) {
          // Continue using new entries
          if (member.service_provider === "cs_meter" && json.meter && json.meter.tdsp_duns) {
            let newJson = { meters: [{ ...json.meter, project_allocation: json.project_allocation }], service_provider: member.service_provider }
            dispatch(receiveMemberMeterInfo(newJson));
          } else {
            let newJson = { meters: [{ ...json.meter, project_allocation: json.project_allocation }], service_provider: member.service_provider }
            dispatch(receiveMemberMeterInfo(newJson));
          }
        } else {
          let newJson = json;
          newJson.meters = [];
          dispatch(receiveMemberMeterInfo(newJson));
        }
      });
    })
  };
}

export function requestMemberMeterInfo(memberID) {
  return (dispatch, getState) => {
    return fetch(constants.MEMBER_GET_URL, { memberID }).then((member) => {
      let url = constants.METER_SEARCH_URL
      let query = { include_inactive: false }
      if (member.service_provider === "eci_meter") {
        url = constants.ECI_METER_URL
        query['memberId'] = memberID
      }
      else if (member.service_provider === "cs_meter") {
        url = constants.CS_METER_URL
        query['member_id'] = memberID
      } else {
        url = constants.PROTON_METER_URL
        query['memberID'] = memberID
      }

      return fetch(url, query).then((json) => {
        if (json.meters) {
          // Continue as before
          let newJson = json;
          dispatch(receiveRelatedMemberMeterInfo(newJson));
        }
        else if (json.meter) {
          // Continue using new entries
          if (member.service_provider === "cs_meter" && json.meter && json.meter.tdsp_duns) {
            let newJson = { meters: [{ ...json.meter, project_allocation: json.project_allocation }] }
            dispatch(receiveRelatedMemberMeterInfo(newJson));
          } else {
            let newJson = { meters: [{ ...json.meter, project_allocation: json.project_allocation }] }
            dispatch(receiveRelatedMemberMeterInfo(newJson));
          }
        } else {
          let newJson = json;
          newJson.meters = [];
          dispatch(receiveRelatedMemberMeterInfo(newJson));
        }
      });
    })
  };
}

// TODO look into if the REQUEST_MEMBER_INFO action is ever consumed
function requestMemberInfo() {
  return {
    type: constants.REQUEST_MEMBER_INFO,
    isFetching: true,
  };
}

function receiveMemberUserInfo(json) {
  return {
    type: constants.RECEIVE_MEMBER_USER_INFO,
    isFetching: false,
    memberUserInfo: json,
  };
}

function receiveMemberMeterInfo(json) {
  return {
    type: constants.RECEIVE_MEMBER_METER_INFO,
    isFetching: false,
    memberMeterInfo: json,
  };
}

function receiveRelatedMemberMeterInfo(json) {
  return {
    type: constants.RECEIVE_RELATED_MEMBER_METER_INFO,
    memberMeterInfo: json,
  };
}

function memberDppSuccess(payload) {
  return {
    type: constants.MEMBER_DPP_GET_SUCCESS,
    payload,
  };
}

function memberDppFailure(payload) {
  return {
    type: constants.MEMBER_DPP_GET_FAILURE,
    payload,
  };
}

function memberDppCancelRequest(payload) {
  return {
    type: constants.MEMBER_DPP_CANCEL_REQUEST,
    payload,
  }
}
function memberDppCancelSuccess(payload) {
  return {
    type: constants.MEMBER_DPP_CANCEL_SUCCESS,
    payload
  }
}

function memberDPPCancelFailure(payload) {
  return {
    type: constants.MEMBER_DPP_CANCEL_FAILURE,
    payload
  }
}

export const fetchDpps = (accountID) => (dispatch) => {
  return fetch(constants.MEMBER_DPP_GET_URL, { account_id: parseInt(accountID) })
    .then((res) => {
      dispatch(memberDppSuccess(res.DPPs));
    })
    .catch((err) => {
      dispatch(memberDppFailure(err.message));
    });
};

export const fetchValidateDpp = (accountID) => (dispatch) => {
  return fetch(constants.MEMBER_DPP_VALIDATE_URL, { account_id: parseInt(accountID) })
};

export const fetchCreateDpp = (params) => (dispatch) => {
  return fetch(constants.MEMBER_DPP_CREATE_URL, params);
};

export const fetchCancelDpp = (params) => async (dispatch) => {
  try {
    await dispatch(memberDppCancelRequest());
    const res = await fetch(constants.MEMBER_DPP_CANCEL_URL, params);
    dispatch(memberDppCancelSuccess(res));
  } catch (error) {
    dispatch(memberDPPCancelFailure(error));
  }
}

function memberLevelBillingSuccess(payload) {
  return {
    type: constants.MEMBER_LIST_LEVEL_BILLING_SUCCESS,
    payload,
  };
}

function memberLevelBillingFailure(payload) {
  return {
    type: constants.MEMBER_LIST_LEVEL_BILLING_FAILURE,
    payload,
  };
}

export const fetchLevelBillings = (accountID) => (dispatch) => {
  return fetch(constants.MEMBER_LIST_LEVEL_BILLING_URL, {
    account_id: parseInt(accountID),
  })
    .then((res) => {
      dispatch(memberLevelBillingSuccess(res.LevelBillings));
    })
    .catch((err) => {
      dispatch(memberLevelBillingFailure(err.message));
    });
};

export const fetchCreateLevelBilling =
  ({ account_id, amount }) =>
    () => {
      return fetch(constants.MEMBER_CREATE_LEVEL_BILLING_URL, {
        account_id: parseInt(account_id),
        status: "pending",
        amount,
      });
    };
