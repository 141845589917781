import React, { Component } from "react";
import { bindActionCreators } from "redux";
import selectDnpContainer from "../selectors/dnp";
import * as actions from "../actions/dnp";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router";
import Button from "../components/Button"
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Error from "@material-ui/icons/Error";
import { ClipLoader } from "react-spinners";
import { withStyles } from "@material-ui/core/styles";
import Modal from "react-modal";
import modalStyle from "../styles/modal";

const styles = () => ({
  row: {
    padding: "5px 0",
    display: "flex",
  },
  column: {
    width: "50%",
  },
  exportContainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

class Dnp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberID: "",
    };
  }

  componentDidMount() {
    const { initialized, isLoading, doFetchDnpCandidates } = this.props;
    if (!initialized) {
      doFetchDnpCandidates();
    }
  }

  getDate = (value) => {
    const d = value.split("T")[0];
    return moment(d).format("MMM DD, YYYY");
  };

  refresh = () => {
    this.props.doFetchDnpCandidates();
  };

  render() {
    const {
      accountsByMeters,
      doSendDnpRequests,
      doSendSnoozeRequests,
      toggleCandidateSelect,
      allSelected,
      toggleCandidatesSelectAll,
      initialized,
      isLoading,
      adminUser
    } = this.props;

    const { isMeterDnpManager } = adminUser.permissions
    const columns = [
      {
        id: "checkbox",
        accessor: "selected",
        style: { margin: "auto" },
        align: "right",
        Header: (
          <Checkbox
            type="checkbox"
            className="checkbox"
            checked={allSelected}
            onChange={() => toggleCandidatesSelectAll()}
          />
        ),
        Cell: (row) => {
          if (row.original.isProcessing) {
            return (
              <div style={{ marginLeft: "50px" }}>
                <ClipLoader sizeUnit="px" size={20} color="#ED0874" loading />
              </div>
            );
          }

          return (
            <div>
              <Checkbox
                type="checkbox"
                className="checkbox"
                style={{ marginLeft: "35px" }}
                checked={Boolean(row.value)}
                onChange={() =>
                  toggleCandidateSelect(
                    row.original.account_id,
                    row.original.ID
                  )
                }
              />
              {row.original.errorMessage && (
                <Tooltip
                  title={row.original.errorMessage}
                  aria-label={row.original.errorMessage}
                >
                  <Error color="error" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        Header: "Member ID",
        accessor: "memberID",
        Cell: (rowInfo) => {
          return (
            <Link to={`/members/${rowInfo.value}/details`}>
              {rowInfo.value}
            </Link>
          );
        },
      },
      {
        Header: "Meter Identifier",
        accessor: "meterIdentifier",
      },
      {
        Header: "Account",
        accessor: "account_id",
      },
      {
        Header: "Account Type",
        accessor: "polr_customer_class",
      },
      {
        Header: "City",
        accessor: "city",
      },{
        Header: "County",
        accessor: "county",
      },
      {
        Header: "Balance",
        accessor: "account_balance",
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          return Number(a) > Number(b) ? 1 : -1;
        },
      },
      {
        Header: "Amount Due",
        accessor: "amount_due",
        Cell: (rowInfo) => {
          return `$${rowInfo.value}`;
        },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          return Number(a) > Number(b) ? 1 : -1;
        },
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: (rowInfo) => {
          return <>{moment(rowInfo.value.substring(0, 10)).format("MM/DD/YYYY")}</>;
        },
      },
      {
        Header: "TDSP",
        accessor: "tdsp_name",
      },
      {
        Header: "Service Point Status",
        accessor: "status",
      },
      {
        Header: "Last Payment",
        accessor: "last_payment_amount",
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          return Number(a) > Number(b) ? 1 : -1;
        },
      },
      {
        Header: "Last Payment Date",
        accessor: "last_payment_date",
        Cell: (rowInfo) => {
          return <>{moment(rowInfo.value.substring(0, 10)).format("MM/DD/YYYY")}</>;
        },
      },
      {
        Header: "Last Payment Status",
        accessor: "last_payment_status",
      },
    ];

    return (
      <div>
        <Modal
          isOpen={isLoading}
          contentLabel="Minimal Modal Example"
          style={modalStyle.loadingModal}
        >
          <ClipLoader sizeUnit="px" size={150} color="#ED0874" loading />
        </Modal>
        <ReactTable
          filterable
          data={accountsByMeters}
          columns={columns}
          className="-striped -highlight"
          defaultPageSize={10}
        />
        <Button
          color="secondary"
          variant="contained"
          onClick={async () => await doSendDnpRequests(this.state.memberID)}
          style={{ marginTop: 20, marginRight: 20 }}
          disabled={!isMeterDnpManager}
        >
          Submit DNP
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={async () => await doSendSnoozeRequests(this.state.memberID)}
          style={{ marginTop: 20 }}
          disabled={!isMeterDnpManager}
        >
          1-Day Snooze
        </Button>
      </div>
    );
  }
}

Dnp.propTypes = {
  accountsByMeters: PropTypes.arrayOf(PropTypes.object),
  doFetchDnpCandidates: PropTypes.func.isRequired,
  doSendDnpRequests: PropTypes.func.isRequired,
  toggleCandidateSelect: PropTypes.func.isRequired,
  toggleCandidatesSelectAll: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
};

Dnp.defaultProps = {
  accountsByMeters: [],
};

const mapStateToProps = selectDnpContainer();

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  dispatch,
});

const ConnectedTDSP = connect(mapStateToProps, mapDispatchToProps)(Dnp);

export default withStyles(styles)(ConnectedTDSP);
