import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import jwtDecode from "jwt-decode";

function receiveAuthHeaders(headers) {
  return {
    type: constants.RECEIVE_AUTH_HEADERS,
    isFetching: false,
    headers,
  };
}

function requestAuthHeaders() {
  return {
    type: constants.REQUEST_AUTH_HEADERS,
    isFetching: true,
  };
}

function receiveUserLogInError(payload) {
  return {
    type: constants.RECEIVE_USER_LOG_IN_ERROR,
    payload,
  };
}

export function hideSnackBar() {
  return {
    type: constants.HIDE_SNACKBAR,
  };
}

export function updateUserData({ userID, permissions }) {
  return {
    type: constants.USER_LOGGED_IN,
    userID,
    permissions: getUserPermissions(permissions),
  };
}

const getUserPermissions = (permissions) => {    
    const userPermissions = permissions || {}
    const isTransactionManager = userPermissions[constants.PERMISSIONS_METER_TRANSACTION_MANAGER] !== "deny"
    const isMeterDnpManager = userPermissions[constants.PERMISIONS_METER_DNP_MANAGER] !== "deny"
    const isUserManager = userPermissions[constants.PERMISSIONS_USER_MANAGER] === "allow"
    const isReferralCreditsManager =userPermissions[constants.PERMISSIONS_REFERRAL_CREDITS_APPROVAL] ==="allow";
    const isFileUploadManager =userPermissions[constants.PERMISIONS_FILE_UPLOAD_MANAGER] ==="allow";
    const isLMIApproverManager =userPermissions[constants.PERMISIONS_LMI_APPROVER_MANAGER] ==="allow";
    const isEnrollmentManager =userPermissions[constants.PERMISIONS_ENROLLMENT_MANAGER] ==="allow";
    const isCSMeterManager =userPermissions[constants.PERMISIONS_CS_METER_MANAGER] ==="allow";
    const isProductManager =userPermissions[constants.PERMISSIONS_PRODUCT_MANAGER] ==="allow";
    const isPromoManager =userPermissions[constants.PERMISSIONS_PROMO_MANAGER] ==="allow";
    const isBillingManager = userPermissions[constants.PERMISSIONS_BILLING_MANAGER] === "allow"
    const isCustomerImpersonate = userPermissions[constants.PERMISSIONS_CUSTOMER_IMPERSONATE] === "allow"
    return { 
        isTransactionManager,
        isReferralCreditsManager,
        isUserManager,
        isMeterDnpManager,
        isFileUploadManager,
        isLMIApproverManager,
        isEnrollmentManager,
        isCSMeterManager,
        isProductManager,
        isPromoManager,
        isBillingManager,
        isCustomerImpersonate,
    }
}

export function login(data) {
  return (dispatch) => {
    dispatch(requestAuthHeaders());
    return fetch(constants.LOGIN, data)
      .then((json) => {
        localStorage.setItem("access_token", json.access_token);
        localStorage.setItem("refresh_token", json.refresh_token);
        localStorage.setItem("userID", json.userID);
        const jwt = jwtDecode(json.access_token);
        if (jwt.permissions[constants.PERMISSIONS_ADMIN_ACCESS] !== "allow") {
          dispatch(receiveUserLogInError({ message: "Insuffient Access" }));
          return;
        }

        dispatch(receiveAuthHeaders(json));
        dispatch(
          updateUserData({ userID: json.userID, permissions: jwt.permissions })
        );
        dispatch(getEmailAddress(json.userID));
      })
      .catch((error) => {
        dispatch(receiveUserLogInError(error));
      });
  };
}

export function updateUserEmail(email) {
  return {
    type: constants.USER_EMAIL_UPDATED,
    email,
  };
}

function getEmailAddress(userID) {
  return (dispatch) =>
    fetch(constants.GET_USER_URL, { userID })
      .then((res) => {
        const email = res.email;
        localStorage.setItem("email", email);
        dispatch(updateUserEmail(email));
      })
      .catch((error) => {
        dispatch(receiveUserLogInError(error));
      });
}

function hydrateUser(payload) {
  return {
    type: constants.HYDRATE_USER,
    payload,
  };
}

export function hydrate() {
  const email = localStorage.getItem("email");
  const userID = localStorage.getItem("userID");
  const access_token = localStorage.getItem("access_token");
  const jwt = access_token && access_token !=="undefined" ? jwtDecode(access_token) : {};
  const payload = {
    userID,
    email,
    permissions: getUserPermissions(jwt.permissions),
    roles: jwt.roles
  };
  return (dispatch) => dispatch(hydrateUser(payload));
}

export function logout() {
  localStorage.clear();
  window.location.href = "/login";
  return {
    type: constants.USER_LOGGED_OUT,
  };
}
