import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "../Snackbar"
import { confirmationError, confirmationSuccess } from "../../styles/common.css";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import MemberPromos from "./MemberPromos";
import SearchablePromos from "./SearchablePromos";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}))

const Promo = props => {
  const {
    params,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isFetching,
  } = props

  const classes = useStyles()

  return <div>
    <h4>Promos</h4>
    {(params && params.memberId) ? <MemberPromos {...props} /> : <SearchablePromos {...props} />}
    {
      successMessage || errorMessage &&
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="message-id"
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
    }
    <Backdrop className={classes.backdrop} open={isFetching}>
      <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
    </Backdrop>
    <br />
  </div>
}

export default Promo;
