import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import Button from "../components/Button"
import { subNavigation } from "../styles/navigation.css";
import selectDnpContainer from "../selectors/dnp";
import { connect } from "react-redux";

class DnpRnp extends Component {

  convertToCSV(data) {
    const headers = [
      {
        Header: "Member ID",
        accessor: "memberID",
      },
      {
        Header: "Meter Identifier",
        accessor: "meterIdentifier",
      },
      {
        Header: "Account",
        accessor: "account_id",
      },
      {
        Header: "Account Type",
        accessor: "polr_customer_class",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "County",
        accessor: "county",
      },
      {
        Header: "Balance",
        accessor: "account_balance",
      },
      {
        Header: "Amount Due",
        accessor: "amount_due",
      },
      {
        Header: "Due Date",
        accessor: "due_date",
      },
      {
        Header: "TDSP",
        accessor: "tdsp_name",
      },
      {
        Header: "Service Point Status",
        accessor: "status",
      },
      {
        Header: "Last Payment",
        accessor: "last_payment_amount",
      },
      {
        Header: "Last Payment Date",
        accessor: "last_payment_date",
      },
      {
        Header: "Last Payment Status",
        accessor: "last_payment_status"
      }
    ]
    const header = headers.map(x => x.Header).join(",");
    const values = data.map(x => headers.map(y => x[y.accessor]).join(","));
    const csv = [header, ...values].join("\n");
    const csvContent = `data:text/csv;charset=utf-8,${csv}`;
    const encodedURI = encodeURI(csvContent);
    window.open(encodedURI);
  }

  render() {
    const { children, accountsByMeters, adminUser } = this.props;
    return (
      <div>
        <header className={subNavigation}>
          <Link
            to={`/dnprnp/dnpqueue`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            DNP Queue
          </Link>{" "}
          <Link
            to={`/dnprnp/dnprnptransactions`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Disconnect Notice
          </Link>{" "}
          {
            window.location.href.includes('dnpqueue') &&
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.convertToCSV(accountsByMeters)}
              style={{ float: "right", marginBottom: "5px" }}
              disabled={!(accountsByMeters && accountsByMeters.length > 0)}
            >
              EXPORT
            </Button>
          }
        </header>
        {children}
      </div>
    );
  }
}

DnpRnp.propTypes = {
  children: PropTypes.object,
};

DnpRnp.defaultProps = {
  children: {},
};

const mapStateToProps = selectDnpContainer();

export default connect(mapStateToProps, null)(DnpRnp);

